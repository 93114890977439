import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Dictionary } from "lodash";

@Component({
    selector: "rl-security-policy-cell",
    templateUrl: "./security-policy-cell.component.html",
    styleUrls: ["./security-policy-cell.component.scss"]
})
export class SecurityPolicyCellComponent {
	@Input()
	rightFlag: number;

	@Input()
	description: string;

	@Input()
	rightStatuses: Dictionary<number> = {};

	@Output()
	onChange: EventEmitter<number> = new EventEmitter<number>();

    constructor() { }

    onClick() {
        this.onChange.emit(this.rightFlag);
    }
}