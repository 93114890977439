<aw-wizard [awNavigationMode] class="rl-wizard mt-3" navBarLayout="small" [disableNavigationBar]="true">
	<aw-wizard-step [stepTitle]="'Create Source Field'" *ngIf="isCreate">
		<div class="modal-body" *ngIf="formLoaded; else isLoading">
			<div class="row">
				<div class="col-8">
					<div class="char-data__group mt-3">
						<div class="char-data__group__element"
							[class.has-error]="formGroup.hasError('fieldNameRequired') && formGroup.controls.fieldName.touched">
							<div class="char-data__label pt-2">
								<span>Field Name</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-text-input [formControl]="formGroup.controls.fieldName" [placeholder]="'Field Name'"
										[dataLabel]="'textInputFieldName'"></rl-text-input>
								</div>
								<div *ngIf="formGroup.hasError('fieldNameRequired') && formGroup.controls.fieldName.touched" class="help-block">Source
									Field Name is required.</div>
							</div>
						</div>
						<div class="char-data__group__element"
							[class.has-error]="(formGroup.hasError('mergeFieldLabelRequired') && (formGroup.controls.mergeFieldLabel.touched || formGroup.controls.fieldName.touched)) || formGroup.hasError('mergeFieldLabelInvalid')">
							<div class="char-data__label pt-2">
								<span>Tag Label</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-text-input [formControl]="formGroup.controls.mergeFieldLabel" [placeholder]="'Tag Label'"
										[dataLabel]="'textInputTagLabel'"></rl-text-input>
								</div>
								<div *ngIf="formGroup.hasError('mergeFieldLabelRequired') && (formGroup.controls.mergeFieldLabel.touched || formGroup.controls.fieldName.touched)"
									class="help-block">Tag Label is required.</div>
								<div *ngIf="formGroup.hasError('mergeFieldLabelInvalid') && formGroup.controls.mergeFieldLabel.touched"
									class="help-block">Tag Label is invalid.</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Tag Label Description</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-text-input [formControl]="formGroup.controls.mergeFieldDescription" [placeholder]="'Tag Label Description'"
										data-cy="textInputTagLabelDescription" />
								</div>
							</div>
						</div>
						<div class="char-data__group__element" *rlFeatureEnabled="'extractContractOption'">
							<div class="char-data__label pt-2">
								<span>Extractable</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-checkbox-input [label]="'Yes'" [formControl]="formGroup.controls.extractable"
										[dataLabel]="'checkboxInputExtractableYes'" class="d-block py-1"></rl-checkbox-input>
								</div>
							</div>
						</div>
						<div class="char-data__group__element"
							[class.has-error]="formGroup.hasError('dataTypeRequired') && formGroup.controls.dataType.touched">
							<div class="char-data__label pt-2">
								<span>Data Type</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-dropdown-single [(formControl)]="formGroup.controls.dataType" [options]="dataTypeList"
										[showEmptyOption]="true" [dataLabel]="'selectDropdownDataType'">
									</rl-dropdown-single>
								</div>
								<div *ngIf="formGroup.hasError('dataTypeRequired') && formGroup.controls.dataType.touched" class="help-block">Data
									Type is required.</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Can be Multiple</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-checkbox-input [label]="'Yes'" [formControl]="formGroup.controls.canBeMultiple"
										[dataLabel]="'checkboxInputCanBeMultipleYes'" class="d-block py-1"></rl-checkbox-input>
								</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Max Length</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-number-input [formControl]="formGroup.controls.maxLength"
										[dataLabel]="'textInputMaxLength'"></rl-number-input>
								</div>
							</div>
						</div>
						<!-- Only show the PickList Source field if Data Type is set to AlphaNum, Money or Checkbox (Classic parity) -->
						<div class="char-data__group__element" *ngIf="(picklistDataTypeIds.includes(formGroup.controls.dataType.value.key))">
							<div class="char-data__label pt-2">
								<span>Picklist Source</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-dropdown-single [(formControl)]="formGroup.controls.pickListSource" [options]="pickListSources"
										[showEmptyOption]="true" [dataLabel]="'selectDropdownPicklistSource'">
									</rl-dropdown-single>
								</div>
							</div>
						</div>
						<div class="char-data__group__element" [class.has-error]="formGroup.hasError('systemIndicatorRequired')">
							<div class="char-data__label pt-2">
								<span>System Indicator</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-number-input [formControl]="formGroup.controls.systemIndicator"
										[dataLabel]="'numberInputSystemIndicator'"></rl-number-input>
								</div>
								<div *ngIf="formGroup.hasError('systemIndicatorRequired') && formGroup.controls.systemIndicator.touched"
									class="help-block">System Indicator is required.</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Report Indicator</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-checkbox-input [label]="'Yes'" [formControl]="formGroup.controls.reportIndicator"
										[dataLabel]="'checkboxInputReportIndicatorYes'" class="d-block py-1"></rl-checkbox-input>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<ng-content select="[step-one-footer]"></ng-content>
			<button type="button" class="btn btn-success ms-3" [disabled]="isFirstStepFormValid(formGroup)" awNextStep
				data-cy="continueStepOneButton">Continue to Template Field Settings <i class="far fa-chevron-right ms-1"></i></button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Template Field Settings'">
		<div class="modal-body" *ngIf="formLoaded; else isLoading">
			<div class="row">
				<div class="col-8">
					<div class="char-data__group mt-3">
						<div *ngIf="!isCreate" class="char-data__group__element" [class.has-error]="formGroup.hasError('fieldNameRequired')">
							<div class="char-data__label pt-2">
								<span>Field Name</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-text-input [formControl]="formGroup.controls.fieldName" [placeholder]="'Field Name'"
										[dataLabel]="'textInputFieldName'"></rl-text-input>
								</div>
								<div *ngIf="formGroup.hasError('fieldNameRequired') && formGroup.controls.fieldName.touched" class="help-block">
									Source Field Name is required.</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Template Section</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-dropdown-single [(formControl)]="formGroup.controls.templateSection" [options]="templateSections"
										[showEmptyOption]="true" [dataLabel]="'selectDropdownTemplateSection'">
									</rl-dropdown-single>
								</div>
							</div>
						</div>
						<div class="char-data__group__element"
							*ngIf="picklistDataTypeIds.includes(formGroup.controls.dataType.value.key) && formGroup.controls.pickListSource?.value?.pickListSourceID !== null"
							[class.has-error]="formGroup.hasError('pickListNameIdRequired') && formGroup.controls.pickListNameId.touched">
							<div class="char-data__label pt-2">
								<span>Picklist</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-dropdown-single [formControl]="formGroup.controls.pickListNameId" [options]="pickListSourceListFiltered"
										[showEmptyOption]="false" [dataLabel]="'selectDropdownPicklist'">
									</rl-dropdown-single>
									<div *ngIf="formGroup.hasError('pickListNameIdRequired') && formGroup.controls.pickListNameId.touched"
										class="help-block">Picklist is required.</div>
								</div>
							</div>
						</div>
						<div class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Required</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display">
									<rl-dropdown-single [(formControl)]="formGroup.controls.required" [options]="requiredOptionsList"
										[showEmptyOption]="false" [dataLabel]="'selectDropdownRequired'">
									</rl-dropdown-single>
								</div>
							</div>
						</div>
						<div *ngIf="showDateAlertButton" class="char-data__group__element">
							<div class="char-data__label pt-2">
								<span>Add Date Alert</span>
							</div>
							<div class="char-data__control container m-0">
								<div class="char-data-element-display" ngbDropdown data-cy="dateAlertDropdown">
									<button class="btn btn-icon btn-success ms-2" ngbDropdownToggle title="Add Date Alert" data-cy="AddDateAlert">
										<i class="far fa-plus"></i>
									</button>
									<div ngbDropdownMenu>
										<button ngbDropdownItem data-cy="CreateCustomAlertButton" (click)="addCustomNotification()">Create Custom
											Alert</button>
										<div class="dropdown-divider"></div>
										<h6 class="dropdown-header">Alert Profiles</h6>
										<button ngbDropdownItem *ngFor="let notification of notifications$ | async"
											(click)="addAlertFromDraft(notification)" [attr.data-cy]="notification.id">
											{{notification.label}}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="char-data__group">
							<div class="char-data__group__element mt-3">
								<div class="char-data__label pt-2">
									<span>Conditional - Show only when triggered by</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="char-data-element-display">
										<rl-dropdown-single [(formControl)]="formGroup.controls.conditionalTriggerFieldId" [options]="triggerFields"
											[showEmptyOption]="true" [dataLabel]="'selectDropdownConditional'">
										</rl-dropdown-single>
									</div>
								</div>
							</div>
							<div class="char-data__group__element" *ngIf="formGroup.get('conditionalTriggerFieldId').value?.characteristicID"
								[class.has-error]="formGroup.hasError('conditionalTriggerValueRequired')">
								<div class="char-data__label pt-2">
									<span>Conditional Trigger Value</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="char-data-element-display" *ngIf="lovMetaDataLoaded; else isLoading">
										<input type="checkbox" class="form-check-input" [(ngModel)]="anyValueSelected" id="anyValueSelect"
											(ngModelChange)="emitAnyValueChange()" data-cy="checkboxInputConditionalTriggerAnyValue">
										<label for="anyValueSelect" class="form-check-label ms-2">Any Value</label>
										<rl-tree-list-edit-control [lovs]="lovs" [charValueGroups]="charValueGroups"
											[selectedValueIDs]="selectedValueIds" (selectedValueIDsChange)="setSelected($event)">
										</rl-tree-list-edit-control>
									</div>
									<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
										(click)="clearConditionalFields()" data-cy="clearConditionalFields">Clear
										Conditional Fields</button>
								</div>
							</div>
							<div class="char-data__group__element" *ngIf="!systemSourceField">
								<div class="char-data__label pt-2">
									<span>Include this Field when record is copied</span>
								</div>
								<div class="char-data__control container m-0">
									<div class="char-data-element-display">
										<rl-checkbox-input [label]="'Yes'" [formControl]="formGroup.controls.copyIndicator"
											[dataLabel]="'checkboxInputCopyIndicatorYes'" class="d-block py-1"></rl-checkbox-input>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="!isCreate" class="col-4 px-4">
					<div class="alert alert-info bg-light" *ngIf="legendDataLoaded; else isLoading">
						<h4>Source Field Information</h4>
						<ul class="list-unstyled mb-0">
							<li class="row">
								<strong class="col-6 py-1 text-end">Field Name</strong>
								<span class="col-6 py-1">{{ legendSourceFieldName }}</span>
							</li>
							<li class="row">
								<strong class="col-6 py-1 text-end">Tag Label Description</strong>
								<span class="col-6 py-1">{{ legendDescription }}</span>
							</li>
							<li class="row">
								<strong class="col-6 py-1 text-end">Data Type</strong>
								<span class="col-6 py-1">{{ legendDataTypeLabel }}</span>
							</li>
							<li class="row" *ngIf="legendPickListSource && picklistDataTypeIds.includes(formGroup.controls.dataType.value.key)">
								<strong class="col-6 py-1 text-end">Picklist Source</strong>
								<span class="col-6 py-1">{{ legendPickListSource }}</span>
							</li>
						</ul>
					</div>
					@if (isDateType) {
					<div *ngIf="isDateAlertsBlockLoaded; else isLoading" class="date-alerts">
						<h4 class="border-bottom mb-0 pb-2">Date Alerts</h4>
						@if (alerts.length === 0) {
						<div class="d-flex align-items-center p-1 border-bottom">
							<h5 class="m-0 text-center">No Date Alerts found.</h5>
						</div>
						} @else {
						<div class="scroll-vert">
							@for(alert of alerts; track alert.id) {
							<div class="date-alerts-row d-flex align-items-center justify-content-between p-1 border-bottom">
								<a [routerLink]="[]" (click)="editAlert($event, alert)" data-cy="editDateAlert">
									<rl-alert-title [characteristicMetaDatas]="characteristicMetaData" [cmd]="templateFieldModel" [alert]="alert"
										[isConfig]="isConfig">
									</rl-alert-title>
								</a>
								<div class="hover-icons text-end">
									<button class="btn btn-sm btn-icon btn-remove ms-2" (click)="deleteAlert(alert)" title="Delete"
										data-cy="deleteDateAlert">
										<i class="far fa-trash-alt fa-sm"></i>
									</button>
								</div>
							</div>
							}
						</div>
						}
					</div>
					}
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button *ngIf="isCreate" type="button" class="btn btn-info ms-3" awPreviousStep data-cy="previousButtonStepTwo"><i
					class="far fa-chevron-left me-1"></i>
				Previous</button>
			<ng-content select="[step-two-footer]"></ng-content>
			<button type="button" class="btn btn-success ms-3" [disabled]="formGroup && !formGroup.valid" awNextStep
				data-cy="continueButtonStepTwo">Continue to
				Template Field Policies <i class="far fa-chevron-right ms-1"></i></button>
		</div>
	</aw-wizard-step>
	<aw-wizard-step [stepTitle]="'Template Field Policies'">
		<rl-template-field-policy-editor [form]="formGroup" [policies]="policies" [permissions]="permissions" [policiesLoaded]="policiesLoaded"
			[policyModel]="policyModel" [systemIndicator]="systemIndicator" [tagLabel]="tagLabel"
			(contextPermissionsChange)="updateContextPermissions($event)">
		</rl-template-field-policy-editor>
		<div class="modal-footer">
			<button type="button" class="btn btn-info ms-3" awPreviousStep data-cy="previousButtonStepThree"><i class="far fa-chevron-left me-1"></i>
				Previous</button>
			<ng-content select="[step-three-footer]"></ng-content>
			<button type="button" class="btn btn-success ms-3" (click)="submit($event)" [disabled]="formGroup.invalid || isSaving"
				data-cy="continueButtonStepThree">
				<i *ngIf="isSaving" class="far fa-spinner fa-spin"></i>Save</button>
		</div>
	</aw-wizard-step>
</aw-wizard>

<ng-template #isLoading>
	<div class="p-4 text-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>