import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { clone, Dictionary, find, isEqual, transform } from "lodash";
import { AccessResult, AccessRight } from "rl-common/consts";
import { ComponentChanges } from "rl-common/models/i-component-change";
import { ISecurityPolicy, ISecurityPolicyPermission } from "rl-common/services/company/company.models";
import { ITemplateFieldPolicySummaryModel } from "rl-common/services/entity-config/entity-config.models";
import { AclUri, AclUtil } from "rl-common/utils/acl.util";
import { LoaderComponent } from "../../../../../../../common/components/panel/loader/loader.component";
import { SecurityPolicyCellComponent } from "./security-policy-cell/security-policy-cell.component";
import { ICreateTemplateFormData } from "./template-field-policy-editor.models";

@Component({
	selector: "rl-template-field-policy-editor",
	templateUrl: "./template-field-policy-editor.component.html",
	styleUrls: ["./template-field-policy-editor.component.scss"],
	imports: [NgIf, NgFor, NgClass, SecurityPolicyCellComponent, LoaderComponent]
})
export class TemplateFieldPolicyEditorComponent implements OnChanges {

	contextPermissions: Dictionary<ISecurityPolicyPermission>;
	rightStatus: Dictionary<Dictionary<number>> = {};
	effectiveClass: Dictionary<Dictionary<string>> = {};
	acls: Dictionary<string[]>;

	statusKey = { allow: AccessResult.Allow, deny: AccessResult.Deny, neutral: AccessResult.NotSet };

	securityRights = [
		{
			name: "Read",
			rightFlag: AccessRight.Read
		},
		{
			name: "Write",
			rightFlag: AccessRight.Write
		}
	];

	@Input()
	form: FormGroup<ICreateTemplateFormData>;

	@Input()
	policies: ISecurityPolicy[];

	@Input()
	permissions: { [key: string]: ISecurityPolicyPermission[] };

	@Input()
	policiesLoaded: boolean;

	@Input()
	policyModel: ITemplateFieldPolicySummaryModel;

	@Input()
	systemIndicator: number;

	@Input()
	tagLabel: string;

	@Output()
	contextPermissionsChange = new EventEmitter<Dictionary<ISecurityPolicyPermission>>();

	constructor() { }

	ngOnChanges(changes: ComponentChanges<this>): void {
		if (changes.permissions && !isEqual(changes.permissions.previousValue, changes.permissions.currentValue)) {
			this.getAcls();
			this.setContextFromAcls();
			this.updateStatus();
		}

		if (changes.contextPermissions && isEqual(changes.contextPermissions.previousValue, changes.contextPermissions.currentValue)) {
			console.log("Context permissions changed");
		}
	}

	getAcls() {
		this.acls = Object.keys(this.permissions).reduce((acc, key) => {
			const aclStrings = this.permissions[key].map(p => this.permissionToAcl(p));
			acc[key] = aclStrings;

			return acc;
		}, {});
	}

	getEffectiveStatusClass(effectiveStatus: number) {
		let output = "effective-neutral";
		switch (effectiveStatus) {
			case 1:
				output = "success";
				break;
			case -1:
				output = "danger";
				break;
		}

		return output;
	}

	setContextFromAcls() {
		this.contextPermissions = transform(this.permissions, (acc, value, key) => acc[key] =
			clone(find(value, p =>
				p.charTypeID === this.policyModel.charTypeId &&
				p.templateGroupID === this.policyModel.templateGroupId &&
				p.templateID === this.policyModel.templateId &&
				p.charGroupID === this.policyModel.charGroupId &&
				p.charID === this.policyModel.charId)) ||
			null, {} as Dictionary<ISecurityPolicyPermission>);
	}

	permissionToAcl(p: ISecurityPolicyPermission) {
		const acl = AclUtil.getDataEntityAcl(p.charTypeID, p.templateGroupID, p.templateID, p.charGroupID, p.charID);
		return AclUri.withPermissionValue(acl, p.permissionValue);
	}

	getCharAcl() {
		const charTypeId = this.policyModel.charTypeId;
		const templateGroupId = this.policyModel.templateGroupId;
		const templateId = this.policyModel.templateId;
		const charGroupId = this.policyModel.charGroupId;
		const charId = this.policyModel.charId;
		return AclUtil.getDataEntityAcl(charTypeId, templateGroupId, templateId, charGroupId, charId);
	}

	toggle(policy: ISecurityPolicyPermission, rightFlag: number) {
		if (!this.contextPermissions[policy.securityPolicyID]) {
			this.contextPermissions[policy.securityPolicyID] = this.createNewPermission(policy.securityPolicyID);
		}

		const permission = this.contextPermissions[policy.securityPolicyID];
		const currentRightStatus = AclUtil.GetRightStatus(permission.permissionValue, rightFlag);

		let newStatus: number;

		if (currentRightStatus === AccessResult.NotSet) {
			newStatus = AccessResult.Allow;
		} else if (currentRightStatus === AccessResult.Allow) {
			newStatus = AccessResult.Deny;
		} else if (currentRightStatus === AccessResult.Deny) {
			newStatus = AccessResult.NotSet;
		}

		permission.permissionValue = AclUtil.SetRightStatus(permission.permissionValue, rightFlag, newStatus);

		policy.rightStatus = newStatus;

		if (permission.permissionValue === 0) {
			this.contextPermissions[policy.securityPolicyID] = null;
		}

		this.updateStatus();
	}

	// Likely deprecated, keeping just in case
	// setAsHidden() {
	// 	this.contextPermissions = Object.keys(this.contextPermissions).reduce((acc, securityPolicyId) => {
	//         let permission = this.contextPermissions[securityPolicyId];
	//         if (!permission) {
	//             permission = this.createNewPermission(securityPolicyId);
	//             acc[securityPolicyId] = permission;
	//         } else {
	//             permission.permissionValue = AclUtil.SetRightStatus(permission.permissionValue, AccessRight.All, -1);
	//         }
	//         return acc;
	//     }, {} as Dictionary<ISecurityPolicyPermission>);
	// }

	setAsInternal() {
		const allButRead = AccessRight.Write | AccessRight.Create | AccessRight.Delete | AccessRight.Admin;
		this.contextPermissions = Object.keys(this.contextPermissions).reduce((acc, securityPolicyId) => {
			let permission = this.contextPermissions[securityPolicyId];
			if (!permission) {
				permission = this.createNewPermission(securityPolicyId);
				acc[securityPolicyId] = permission;
			} else {
				permission.permissionValue = AclUtil.SetRightStatus(permission.permissionValue, allButRead, -1);
			}
			return acc;
		}, {} as Dictionary<ISecurityPolicyPermission>);
	}

	private createNewPermission(securityPolicyId: string) {
		return {
			permissionValue: 0,
			charTypeID: this.policyModel.charTypeId,
			templateGroupID: this.policyModel.templateGroupId,
			templateID: this.policyModel.templateId,
			charGroupID: this.policyModel.charGroupId,
			charID: this.policyModel.charId,
			securityPolicyID: securityPolicyId,
			securityPolicyPermissionID: null
		} as ISecurityPolicyPermission;
	}

	updateStatus() {
		this.rightStatus = {};
		this.effectiveClass = {};
		const charAcl = this.getCharAcl();

		this.policies.forEach(p => {
			// reset lookup dictionaries
			this.rightStatus[p.securityPolicyID] = {};
			this.effectiveClass[p.securityPolicyID] = {};
			// get acls including any being edited/created in this context
			const effectiveAcls = this.getEffectiveAcls(p.securityPolicyID);
			// get hierarchical permission value
			const effectivePermissionValue = AclUtil.getEffectivePermissionValue(effectiveAcls, charAcl);
			// get permission for this exact charId
			const contextPermission = this.contextPermissions[p.securityPolicyID];

			this.securityRights.forEach(s => {
				// get status for this charId
				const contextStatus = AclUtil.GetRightStatus(contextPermission !== null ? contextPermission.permissionValue : 0, s.rightFlag);
				// get status across all acls
				const effectivePermissionStatus = AclUtil.GetRightStatus(effectivePermissionValue, s.rightFlag);
				this.rightStatus[p.securityPolicyID][s.rightFlag] = contextStatus;
				this.effectiveClass[p.securityPolicyID][s.rightFlag] = this.getEffectiveStatusClass(effectivePermissionStatus);
			});
		});

		this.contextPermissionsChange.emit(this.contextPermissions);
	}

	getEffectiveAcls(policyId: string) {
		let acls = this.acls[policyId];

		if (this.contextPermissions[policyId] !== null) {
			const contextAcl = this.permissionToAcl(this.contextPermissions[policyId]);
			const aclIndex = acls.findIndex(acl => acl.split("?")[0] === contextAcl.split("?")[0]);

			if (aclIndex >= 0) {
				acls[aclIndex] = contextAcl;
			} else {
				acls = [...acls, contextAcl];
			}
		}

		return acls;
	}

	setAll(event: Event, rightFlag: number, status: number) {
		event.preventDefault();

		this.policies.forEach(policy => {
			if (!this.contextPermissions[policy.securityPolicyID]) {
				if (status !== 0) {
					this.contextPermissions[policy.securityPolicyID] = this.createNewPermission(policy.securityPolicyID);
				} else {
					return;
				}
			}

			const permission = this.contextPermissions[policy.securityPolicyID];
			permission.permissionValue = AclUtil.SetRightStatus(permission.permissionValue, rightFlag, status);

			if (permission.permissionValue === 0 || permission.permissionValue === AccessRight.Admin << 16) {
				this.contextPermissions[policy.securityPolicyID] = null;
			}
		});

		this.updateStatus();
	}

	resetPolicyChanges() {
		this.setContextFromAcls();
		this.updateStatus();
	}
}