import { Injectable } from "@angular/core";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ISecurityPolicyPermission, IWfProcesses } from "rl-common/services/company/company.models";
import { RlHttpClient } from "rl-common/services/rl-http-client.service";
import { AccessTokenValidationResult, ComparisonRequest, ComparisonResult } from "./comparison-tool/comparison-tool.models";

@Injectable({
	providedIn: "root"
})
export class ToolsConfigService {
	
	private _baseUrl = "/tools-config";

	// represents how long temporary credentials are valid
	public expirationHours = 8;

	constructor(private readonly _httpClient: RlHttpClient) {

	}

	getTemplatesFromEnvironment(token: string, charTypeId: number) {
		return this._httpClient.get(`${this._baseUrl}/templates/${charTypeId}?token=${token}`)
			.fetch<ICharacteristicTemplate[]>();
	}

	getWorkflowProcessesFromEnvironment(token: string, charTypeId: number) {
		return this._httpClient.get(`${this._baseUrl}/processes/${charTypeId}?token=${token}`)
			.fetch<IWfProcesses[]>();
	}

	getUserTemplateAccessForEnvironment(token: string) {
		return this._httpClient.get(`${this._baseUrl}/access/template?token=${token}`)
			.fetch<ISecurityPolicyPermission>();
	}

	getUserWorkflowAccessForEnvironment(token: string) {
		return this._httpClient.get(`${this._baseUrl}/access/workflow?token=${token}`)
			.fetch<ISecurityPolicyPermission>();
	}

	compareTemplates(compareEnvironment: string, charTypeId: number, currentEnvironmentTemplateId: number, compareEnvironmentTemplateId: number, token: string) {
		const body = {
			compareEnvironment,
			charTypeId,
			currentEnvironmentItemId: currentEnvironmentTemplateId, 
			compareEnvironmentItemId: compareEnvironmentTemplateId
		};
		return this._httpClient.post(`${this._baseUrl}/compare-templates?token=${token}`)
			.body(body)
			.fetch<ComparisonResult>();
	}

	compareWorkflow(compareEnvironment: string, charTypeId: number, currentEnvironmentWorkflowProcessId: number, compareEnvironmentWorkflowProcessId: number, token: string) {
		const body = {
			compareEnvironment,
			charTypeId,
			currentEnvironmentItemId: currentEnvironmentWorkflowProcessId, 
			compareEnvironmentItemId: compareEnvironmentWorkflowProcessId
		};
		return this._httpClient.post(`${this._baseUrl}/compare-workflow?token=${token}`)
			.body(body)
			.fetch<ComparisonResult>();
	}

	getAccessToken() {
		return this._httpClient.get(`/rest-api/access/token?expirationHours=${this.expirationHours}`)
			.fetch<string>();
	}

	validateAccessToken(accessToken: string) {
		const body = {
			accessToken
		}
		return this._httpClient.post(`/rest-api/access/token/validate`)
			.body(body)
			.fetch<AccessTokenValidationResult>();
	}

	exportTemplateConfigComparison(request: ComparisonRequest, result: ComparisonResult, lastRanDate: string) {
		const body = {
			request,
			result,
			lastRanDate
		}
		return this._httpClient.post(`${this._baseUrl}/export-template-config-comparison`)
			.body(body)
			.fetch<string>();
	}

	exportWorkflowConfigComparison(request: ComparisonRequest, result: ComparisonResult, lastRanDate: string) {
		const body = {
			request,
			result,
			lastRanDate
		}
		return this._httpClient.post(`${this._baseUrl}/export-workflow-config-comparison`)
			.body(body)
			.fetch<string>();
	}

	getCurrentEnvironment(environmentName: string) {
		switch(environmentName) {
			case "demo":
				return "Demo";
			case "dev":
				return "Development";
			case "integration":
				return "Integration";
			case "int_eu":
				return "Integration EU";
			case "local":
				return "Development";
			case "mig_eu":
				return "Migration EU";
			case "migration":
				return "Migration";
			case "pm":
				return "Production Mirror";
			case "pm_eu":
				return "Production Mirror EU";
			case "prod_eu":
				return "Production EU";
			case "production":
				return "Production";
			case "qa":
				return "QA";
			case "qa_eu":
				return "QA EU";
			case "staging":
				return "Staging";
			case "stg_eu":
				return "Staging EU";
		}
		
		return "Unknown";
	}
}
