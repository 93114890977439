<div class="workflow__panels" data-cy="WorkflowWizardPanels">
	<rl-panel-switcher [activeId]="step">
		<div rlPanelSwitcherHead class="modal-header" data-cy="WorkflowWizardModalHeader">
			<h2 class="modal-title">{{ action.actionName}}</h2>
			<button type="button" class="btn btn-close" aria-label="Close" (click)="cancel()"
				data-cy="WorkflowWizardButtonCancel">
				<i class="far fa-times"></i>
			</button>
		</div>
		<rl-panel id="loading">
			loading
		</rl-panel>
		<rl-panel id="generate-document">
			<ng-template rlPanelContent>
				<rl-generate-document [action]="action" (onJobStart)="proceedToProgressStep($event)"
					(onSave)="generateDocumentProgress($event)">
					<button class="btn btn-light" (click)="cancel()"
						data-cy="GenerateDocumentButtonCancel">Cancel</button>
				</rl-generate-document>
			</ng-template>
		</rl-panel>
		<rl-panel id="send-for-signature">
			<ng-template rlPanelContent>
				<rl-send-for-esignature (onJobStart)="proceedToProgressStep($event)" [actionId]="action.actionId" [partiesOnly]="false">
					<button class="btn btn-light" (click)="cancel()" data-cy="SendForEsigButtonCancel">Cancel</button>
				</rl-send-for-esignature>
			</ng-template>
		</rl-panel>
		<rl-panel id="send-for-signature-parties-only">
			<ng-template rlPanelContent>
				<rl-send-for-esignature (onJobStart)="proceedToProgressStep($event)" [actionId]="action.actionId" [partiesOnly]="true">
					<button class="btn btn-light" (click)="cancel()" data-cy="SendForEsigButtonCancel">Cancel</button>
				</rl-send-for-esignature>
			</ng-template>
		</rl-panel>
		<rl-panel id="preview-notifications">
			<ng-template rlPanelContent>
				<rl-preview-notifications *ngIf="executeWorkflowMetaData.notifications" [charTypeId]="charTypeId"
					[models]="models" [action]="action" [notifications]="executeWorkflowMetaData.notifications"
					[documents]="documents" (onSend)="proceedToProgressStep($event)">
					<button class="btn btn-light" (click)="cancel()" data-cy="PreviewNotifsButtonCancel">Cancel</button>
				</rl-preview-notifications>
			</ng-template>
		</rl-panel>
		<rl-panel id="preview-chained">
			<ng-template rlPanelContent>
				<div *ngIf="totalActionCount > 0">
					<rl-feature-flag [key]="'previewChainedWorkflow'">
						<div on>
							<div class="modal-body">
								<h4 class="mb-4">
									The workflow action <strong>{{action.actionName}}</strong> triggered chained
									workflow on
									{{totalActionCount}} records
								</h4>
								<ul class="list-group list-group-flush d-inline-block w-50 ms-3 mb-3">
									<li *ngFor="let actionAssociation of executeWorkflowMetaData.chainedActions"
										class="list-group-item">
										<h5 class="my-1"><strong><rl-entity-link
													[charTypeId]="actionAssociation.charTypeId"
													[recordId]="actionAssociation.recordId"></rl-entity-link></strong>
											{{getWorkflowActionName(actionAssociation)}}</h5>
									</li>
								</ul>
								<p class="mt-2"><em>Note: Chained workflow executes in the background. You may have to
										refresh this
										record to see the relevant updates to affected records.</em></p>
							</div>
							<div class="modal-footer">
								<button class="btn btn-light" (click)="cancel()">Cancel</button>
								<button class="btn btn-success" (click)="executeWorkflowActions()">Execute</button>
							</div>
						</div>
					</rl-feature-flag>
				</div>
			</ng-template>
		</rl-panel>
		<rl-panel id="in-progress">
			<ng-template rlPanelContent>
				<rl-job-progress [jobId]="jobId" [hasNextView]="hasNextView" [allowCloseBeforeComplete]="true"
					[hideModalHeading]="true" [isWithinWizard]="true" (onComplete)="complete()" (onNext)="next()"
					*ngIf="jobId"></rl-job-progress>
				<div *ngIf="totalActionCount > 0">
					<h4>
						The workflow action <strong>{{action.actionName}}</strong> triggered chained workflow on
						<span *ngFor="let chainedAction of executeWorkflowMetaData.chainedActions | keyvalue">
							{{chainedAction.recordId}}
						</span>
					</h4>
					<p class="mt-2"><em>Note: Chained workflow executes in the background. You may have to refresh this
							record to see the relevant updates to affected records.</em></p>
				</div>
			</ng-template>
		</rl-panel>
		<rl-panel id="preview-document">
			<ng-template rlPanelContent>
				<h4 class="m-4">If the document does not load within 10 seconds, click the Close button below. Hover
					over the item
					in your <strong>Files</strong> list and click the <strong>'Open File'</strong> icon: <i
						class="far fa-external-link text-small ms-1 text-grey"></i></h4>
				<rl-preview-document *ngIf="file && file.uri" [file]="file" data-cy="RlPreviewDocument">
				</rl-preview-document>
				<div *ngIf="!file || !file.uri" class="alert alert-danger m-4" data-cy="DocGenAlertError">
					There was an error trying to generate the document.
				</div>
				<div class="modal-footer">
					<button class="btn btn-info" (click)="complete()" data-cy="PreviewDocumentButtonDone">Close</button>
				</div>
			</ng-template>
		</rl-panel>
	</rl-panel-switcher>
</div>