<div class="panel mx-3 mb-3">
	<div class="panel__title">
		<span class="title--overline">Intelligence Center</span>
		<h3>AI Model Information</h3>
	</div>
	<section class="mt-3">
		<h4>Claude 3 Sonnet <em>v1</em></h4>
		<p>Anthropic&nbsp;&nbsp;|&nbsp;&nbsp;Text & Vision Model&nbsp;&nbsp;|&nbsp;&nbsp;Max 200k</p>
		<hr>
		<p>Claude 3 Sonnet by Anthropic strikes the ideal balance between intelligence and speed - particularly for
			enterprise workloads. </p>
		<p>Model attributes - Image to text & code, multilingual conversation, complex reasoning and analysis</p>
		<p>Model ID - anthropic.claude-3-sonnet-20240229-v1:0</p>
	</section>
</div>