import { CurrencyPipe, NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { cloneDeep, Dictionary, isEmpty, last } from "lodash";
import { SearchFieldNames } from "rl-common/components/entities/entity-search/query.models";
import { GridDataSourceBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-builder";
import { GridDataSourceDataChangeStrategyBuilder } from "rl-common/components/grid/datasource/builders/grid-datasource-data-change-strategy-builder";
import { IGridFetchResults } from "rl-common/components/grid/datasource/grid-datasource.models";
import { GridColumn } from "rl-common/components/grid/models/grid-column";
import { GridOptions } from "rl-common/components/grid/models/grid-options";
import { CharTypeId, SortDirection, SystemIndicators } from "rl-common/consts";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { ISearchRequestOptions } from "rl-common/models/i-search-request-options";
import { IAllocatedDimension, IAllocationModel } from "rl-common/services/allocation/allocation.models";
import { AllocationService } from "rl-common/services/allocation/allocation.service";
import { IRightsDimension } from "rl-common/services/company/company.models";
import { CompanyService } from "rl-common/services/company/company.service";
import { AllocationSpreadStrategy } from "rl-common/services/deal/deal.models";
import { DealService } from "rl-common/services/deal/deal.service";
import { EntityData } from "rl-common/services/mod-detail/mod-detail.models";
import { SearchService } from "rl-common/services/search/search.service";
import { TemplateService } from "rl-common/services/template/template.service";
import { DateLocaleType, DateUtil, QueryUtil } from "rl-common/utils";
import { TagLabel } from "rl-common/utils/characteristic.util";
import { NumberUtil } from "rl-common/utils/number.util";
import { forkJoin, of, Subject, Subscription } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { CheckboxInputComponent } from "../../checkbox-input/checkbox-input.component";
import { CellTemplateDirective } from "../../grid/directives/cell-template.directive";
import { GridNestedTemplateDirective } from "../../grid/directives/grid-nested-template.directive";
import { GridTableComponent } from "../../grid/grid-table/grid-table.component";
import { AllocationRowToCreate, AllocationRowWithChanges, IAllocatedAmount, IAllocationRow } from "../financial.models";
import { AllocationSpreadComponent } from "./allocation-spread/allocation-spread.component";
import { AllocationDataSource } from "./launch-allocation-datasource";
import { LaunchAllocationModalService } from "./launch-allocation-modal.service";

export interface IAllocationLaunch {
	allocatedAmount: number;
	allocatedPercentage: number;
}

enum AllocationType {
	ByCatalogRightsDuration = 0,
	EvenlyByCatalog = 1
}

enum AllocationSelection {
	ImmediateChildren = 0,
	SelectedCatalogs = 1
}
enum ViewSelection {
	Default = 0,
	FirstInFirstOut = 1
}



@Component({
    selector: "rl-launch-allocation-modal",
    templateUrl: "./launch-allocation-modal.component.html",
    styleUrls: ["./launch-allocation-modal.component.scss"],
    providers: [LaunchAllocationModalService],
    imports: [ReactiveFormsModule, FormsModule, NgFor, CheckboxInputComponent, NgIf, GridTableComponent, CellTemplateDirective, GridNestedTemplateDirective, AllocationSpreadComponent, CurrencyPipe]
})
export class LaunchAllocationModalComponent implements OnInit {

	@Input()
	dealId: string;

	@Input()
	selected: string[] = [];

	@Input()
	totalFees = 0;

	@Output()
	onCalculate = new EventEmitter<string>();

	@Input()
	totalFeesSym: string;
	usedTemplates: ICharacteristicTemplate[] = [];
	allocationType = AllocationType.EvenlyByCatalog;
	allocationSelection = AllocationSelection.SelectedCatalogs;
	viewSelection = ViewSelection.Default;
	selectedTemplates: number[] = [];
	showAllocation = "Show Allocation Amounts";
	dataSource: AllocationDataSource;
	dataChangeStrategies: GridDataSourceDataChangeStrategyBuilder;
	inheritedRels = false;
	singleRowFeeEdit: { [id: number]: number } = {};
	singleRowFeePercentageEdit: { [id: number]: number } = {};
	allocationFeeChangeLocale: { [id: number]: string } = {};
	allocationFeeChangePercent: { [id: number]: number } = {};
	bulkAllocatedFeeAmount: number;
	bulkAllocatedFeePercentage: number;
	associatedRightsSystemIndicator = SystemIndicators.AssociatedRights;
	totalNumFound = 0;
	catalogs: IAllocationRow[] = [];
	rowsWithChange: AllocationRowWithChanges[] = [];
	expandAllocationAmounts = true;
	columnsToShow: GridColumn<IEntitySearchDoc>[] = [];
	allocationFeeTotal = 0;
	allocationFeePercentTotal = 0;
	initialLoad = true;
	rollUp = false;
	ViewSelection = ViewSelection;
	isLoading = false;
	hasErrors: { [id: number]: boolean } = {};
	amountErrors: Map<string, boolean> = new Map<string, boolean>();
	hasPercentageErrors: { [id: number]: boolean } = {};
	gridOptions: GridOptions<IEntitySearchDoc> = {};
	subscriptions: Subscription[] = [];
	allocationOptions: IAllocationModel[] = [];
	selectedAllocationModel: IAllocationModel = { id: "default", name: "Default", parentCharTemplateId: null, primaryCatalogCharTemplateId: -1, suballocationCatalogCharTemplateId: -1 };
	allocatedDimensions: IAllocatedDimension[] = [];
	originalRows: IAllocationRow[] = [];
	hasBulkPercentage = false;
	hasBulkFee = false;
	hasError = false;
	errorMessages = "";
	displayAmountErrorMessage = "Child amounts must add up to parent amount.";
	isSaving = false;
	errors: string[];
	default = "default";
	allRows: IAllocationRow[] = [];
	suballocationCatalogItems: EntityData[] = [];
	hasAnyAmountError = false;
	allDimensions: IRightsDimension[] = [];

	/** catalog row -> calculated amount rows */
	calculateAllocationSpread$ = new Subject<string>();
	catalogSpreads: Dictionary<IAllocatedAmount[]> = {};
	useCatalogsInsteadOfAmounts = false;
	columnsToAdd: GridColumn<IAllocationRow>[] = [];
	amounts: IEntitySearchDoc[] = [];
	evenlySpreadAmountsForCatalog: Dictionary<IAllocatedAmount[]> = {}


	get isEveryTemplateSelected() {
		return this.selectedTemplates.length > 0 && this.selectedTemplates.length === this.usedTemplates.length;
	}


	constructor(private activeModal: NgbActiveModal,
		private dealService: DealService,
		private templateService: TemplateService,
		private readonly gridDataSourceBuilder: GridDataSourceBuilder,
		private readonly allocationService: AllocationService,
		private readonly _searchService: SearchService,
		private readonly companyService: CompanyService
	) { }

	ngOnInit() {
		this.isLoading = true;
		this.templateService.getUsedTemplates(this.dealId, CharTypeId.Property).subscribe(templates => {
			this.usedTemplates = templates;
		});

		this.companyService.getRightsDimensions().pipe(
			tap((results) => {
				this.allDimensions = results;
			}),
			switchMap(() => this.companyService.getSelectedRightsDimensions()),
			tap((selectedResults) => {
				const sourceIds = selectedResults.map(x => x.charValueSourceID)
				this.allDimensions = this.allDimensions.filter(x => sourceIds.indexOf(x.charValueSourceID) > -1)
			})
		).subscribe();

		this.dataSource = this.gridDataSourceBuilder.buildAllocation()
			.withFetchFn((ds) => {
				const page = Math.floor(ds.rowOffset$.value / ds.pageSize$.value) + 1;
				const pageSize = ds.pageSize$.value;
				const selectedDimensionModel = this.selectedAllocationModel?.id && this.selectedAllocationModel?.id !== "default" ? this.selectedAllocationModel?.id : null;
				return this.dealService.getAllocationRows(this.dealId, this.allocationType, this.allocationSelection, this.selected, selectedDimensionModel, page, pageSize)
					.pipe(
						// grabs the child amounts
						switchMap(results => {
							const relIds = results?.pagedRows?.filter(x => !!x.relId).map(x => x.relId);
							const dimensionDictionary = results.allRows?.length > 0 ? results?.allRows[0].dimensionDisplayDictionary : null;
							this.columnsToAdd = ds.columns$.value;
							this.allDimensions.forEach(dimension => {
								if (dimensionDictionary?.[dimension.charValueSetID]) {
									const column: GridColumn<IAllocationRow> =
									{
										key: dimension.charValueSourceLabel,
										headerName: dimension.charValueSourceLabel,
										renderer: "text",
										width: "270px",
										disableResize: true,
										disablePin: true,
										getCellData: (user => user.dimensionDisplayDictionary?.[dimension.charValueSetID])
									}
									if (this.columnsToAdd.filter(x => x.key === dimension.charValueSourceLabel)?.length < 1) {
										this.columnsToAdd.push(column);
									}
								}

							});
							ds.setColumns(this.columnsToAdd);

							const matchesParentQueries = [
								QueryUtil.$eq(SearchFieldNames.Relationship.childCharTypeID, CharTypeId.Amount),
								QueryUtil.$eq(SearchFieldNames.Relationship.parentCharTypeID, CharTypeId.Relationship),
								QueryUtil.$eq_any(SearchFieldNames.Relationship.parentRecordID, relIds)];

							const parentRelQuery =
								QueryUtil.$join_parent_rel(
									QueryUtil.$and(
										...matchesParentQueries
									)
								);

							// TODO: We need to handle the possibility of too many amounts being loaded into memory, capping to 1000 for now
							const options: ISearchRequestOptions = {
								rows: 1000,
								start: 0,
								sortField: TagLabel.TableSequence,
								sortDir: SortDirection.Ascending,
								fields: [],
								gridViewColumns: []
							};
							if (results.numFound > 0) {
								this.useCatalogsInsteadOfAmounts = results.allRows.filter(x => x.catalogEntitiesForSuballocation?.length > 0).length > 0;

								return this._searchService.search(CharTypeId.Amount, null, parentRelQuery, options)
									.pipe(
										tap((childAmtResults) => {
											if (!this.useCatalogsInsteadOfAmounts) {
												ds.setChildAmounts(childAmtResults)
											}
										}),
										map(() => results)
									);
							} else {
								return of(results);
							}
						}),
						map((results) => {
							this.hasError = false;
							const rows = results?.allRows ?? [];
							const errorRows = rows?.filter(x => x.error != null && x.error !== "");
							if (errorRows.length > 0) {
								this.hasError = true;
								this.isLoading = false;
								const errorsArray = errorRows.map(x => x.error);
								this.errors = ([...new Set(errorsArray)]);
								return { rowCount: 0, rowData: [] } as IGridFetchResults<IAllocationRow>;
							} else {
								rows.forEach(row => {
									const existingChanges = this.rowsWithChange.find(x => x.rightRecordId === row.rightRecId && x.catalogId === row.catalogId && x.paymentScheduleRecordId === row.paymentScheduleId);
									if (existingChanges) {
										this.allocationFeeChangeLocale[row.rowId] = this.toServerAmount(existingChanges.allocatedAmount.toString());
										this.allocationFeeChangePercent[row.rowId] = existingChanges.allocatedAmountPercentage;
									} else {
										this.allocationFeeChangeLocale[row.rowId] = this.toServerAmount(row.allocatedAmount.toString());
										this.allocationFeeChangePercent[row.rowId] = row.allocatedAmountPercentage;
									}
								});
								this.allRows = results.allRows;
								return { rowCount: results.numFound, rowData: results.pagedRows } as IGridFetchResults<IAllocationRow>;
							}
						}),
						tap((results) => {
							if (this.hasError) {
								this.totalNumFound = 0;
							} else {
								this.totalNumFound = results.rowCount;
								this.originalRows = this.allRows;
								this.catalogs = this.allRows;
								this.calculateTotals();
								this.allocationFeePercentTotal = Math.round(this.allocationFeePercentTotal);
								this.initialLoad = false;
							}
							this.isLoading = false;
						})
					);
			});

		const sub1 = this.dataSource.modifiedColumnDatas$.pipe(
			tap((mod) => {
				const columnEdit = mod.get("allocatedFeeAmount");
				if (columnEdit) {
					this.hasBulkFee = true;
					this.bulkAllocatedFeeAmount = +columnEdit[1];
					this.catalogs.forEach(catalog => {
						const edit = columnEdit[1].toString();
						this.allocationFeeChangeLocale[catalog.rowId] = this.toServerAmount(edit);
						this.calculatePercentage(catalog.rowId, edit);
					});
				} else {
					this.bulkAllocatedFeeAmount = 0;
					if (this.catalogs && this.hasBulkFee) {
						this.hasBulkFee = false;
						this.catalogs.forEach(catalog => {
							this.allocationFeeChangeLocale[catalog.rowId] = "0";
							this.calculatePercentage(catalog.rowId, "0");
						});
					}
				}
			})
		).subscribe();
		this.subscriptions.push(sub1);

		const sub2 = this.dataSource.modifiedColumnDatas$.pipe(
			tap((mod) => {
				const columnEdit = mod.get("allocatedFeePercent");
				if (columnEdit) {
					this.hasBulkPercentage = true;
					this.bulkAllocatedFeePercentage = +columnEdit[1];
					this.catalogs.forEach(catalog => {
						this.allocationFeeChangePercent[catalog.rowId] = +columnEdit[1];
						this.calculateFee(catalog.rowId, +columnEdit[1]);
					});
				} else {
					if (this.catalogs && this.hasBulkPercentage) {
						this.hasBulkPercentage = false;
						this.bulkAllocatedFeePercentage = 0;
						this.catalogs.forEach(catalog => {
							this.allocationFeeChangePercent[catalog.rowId] = 0;
							this.calculateFee(catalog.rowId, 0);
						});
					}
				}

			})).subscribe();
		this.subscriptions.push(sub2);

		const sub3 = this.allocationService.getAllocationModelListByParentTemplateId(-1).pipe(
			tap((results) => this.allocationOptions = results)).subscribe();
		this.subscriptions.push(sub3);
		this.subscriptions.push(this.dataSource.fetchRows().pipe(
			switchMap(() => this.openOrCloseTables())
		).subscribe());

		this.calculateAllocationSpread$.pipe(
			debounceTime(200),
			switchMap(() => this.dataSource.childAmounts$)
		).subscribe((childAmountResults) => {
			if (!this.useCatalogsInsteadOfAmounts) {
				this.amounts = childAmountResults?.documents ?? [];
				this.calculateAllocationSpread(this.amounts);
			}
		});
	}

	allocationModelChanged() {
		this.isLoading = true;
		this.rowsWithChange = [];
		this.allocationService.getAllocatedDimensionsByModelId(this.selectedAllocationModel.id).pipe(
			tap((dimensions) => {
				this.allocatedDimensions = dimensions;
			}),
			switchMap(() => this.dataSource.fetchRows()),
			tap((parentRows) => {
				parentRows?.rowData?.forEach(parentRow => {
					if (parentRow.catalogEntitiesForSuballocation?.length > 0) {
						this.useCatalogsInsteadOfAmounts = true;
						this.evenlySpreadAmounts(this.amounts, true, parentRow);
					} else {
						this.useCatalogsInsteadOfAmounts = false;
					}
				});
			}),
			switchMap(() => this.openOrCloseTables())
		).subscribe();
	}

	selectAllTemplates() {
		if (this.isEveryTemplateSelected) {
			this.selectedTemplates = [];
		} else {
			this.selectedTemplates = this.usedTemplates.map(x => x.templateID);
		}
	}

	selectTemplate(templateId: number) {
		if (this.selectedTemplates.indexOf(templateId) >= 0) {
			this.selectedTemplates = this.selectedTemplates.filter(x => x !== templateId);
		} else {
			this.selectedTemplates.push(templateId);
		}
	}

	toggle() {
		this.expandAllocationAmounts = !this.expandAllocationAmounts;
		this.openOrCloseTables().subscribe();
	}

	toServerAmount(value: string) {
		const unmasked = NumberUtil.parseLocaleNumber(value);
		if (unmasked !== "" && isNaN(unmasked)) {
			return NumberUtil.maskLocaleNumber(value);
		}
		return NumberUtil.maskLocaleNumber(unmasked.toString());
	}

	valueChanged(rowId: string, value: string) {
		const result = this.toServerAmount(value);
		const fee = parseFloat(result.replace(/,/g, ""));
		if (isNaN(fee)) {
			this.hasErrors[rowId] = true;
			this.allocationFeeChangeLocale[rowId] = value;
			this.singleRowFeePercentageEdit[rowId] = isNaN(fee) ? 0 : +(100 * fee / this.totalFees).toFixed(2);
			this.allocationFeeChangePercent[rowId] = isNaN(fee) ? 0 : +(100 * fee / this.totalFees).toFixed(2);
		} else {
			this.hasErrors[rowId] = false;
			this.allocationFeeChangeLocale[rowId] = result;
		}
		this.calculatePercentage(rowId, value);
	}

	calculateFee(rowId: string, percentage: number) {
		this.hasPercentageErrors[rowId] = isNaN(percentage);
		const feeAmount = isNaN(percentage) ? 0 : this.toServerAmount((this.totalFees * (percentage / 100)).toString());
		this.singleRowFeeEdit[rowId] = +(this.totalFees * (percentage / 100)).toFixed(2);
		this.allocationFeeChangeLocale[rowId] = feeAmount;
		this.hasErrors[rowId] = false;
		this.calculateTotals(rowId, percentage, true);
		const matchingRow = this.allRows.find(x => x.rowId === rowId);
		const rowWithChange: AllocationRowWithChanges = { rowId: rowId, catalogId: matchingRow.catalogId, rightRecordId: matchingRow.rightRecId, paymentScheduleRecordId: matchingRow.paymentScheduleId, allocatedAmount: +feeAmount, allocatedAmountPercentage: percentage };
		const rowIdsToRemove = this.rowsWithChange.filter(x => x.catalogId === matchingRow.catalogId && x.rightRecordId === matchingRow.rightRecId && x.paymentScheduleRecordId === matchingRow.paymentScheduleId || x.rowId === rowId).map(x => x.rowId);
		this.rowsWithChange = this.rowsWithChange.filter(x => rowIdsToRemove.indexOf(x.rowId) < -1);
		this.rowsWithChange.push(rowWithChange);
	}

	calculatePercentage(rowId: string, feeString: string) {
		const fee = parseFloat(feeString.replace(/,/g, ""));
		const percentageAmount = isNaN(fee) ? 0 : +(100 * fee / this.totalFees).toFixed(2)
		this.singleRowFeePercentageEdit[rowId] = percentageAmount;
		this.allocationFeeChangePercent[rowId] = percentageAmount;
		const total = isNaN(fee) ? 0 : fee;
		this.calculateTotals(rowId, total);
		const matchingRow = this.allRows.find(x => x.rowId === rowId);
		const rowWithChange: AllocationRowWithChanges = { rowId: rowId, catalogId: matchingRow.catalogId, rightRecordId: matchingRow.rightRecId, paymentScheduleRecordId: matchingRow.paymentScheduleId, allocatedAmount: fee, allocatedAmountPercentage: percentageAmount };
		const rowIdsToRemove = this.rowsWithChange.filter(x => x.catalogId === matchingRow.catalogId && x.rightRecordId === matchingRow.rightRecId && x.paymentScheduleRecordId === matchingRow.paymentScheduleId || x.rowId === rowId).map(x => x.rowId);
		this.rowsWithChange = this.rowsWithChange.filter(x => rowIdsToRemove.indexOf(x.rowId) < 0);
		this.rowsWithChange.push(rowWithChange);
	}

	calculateTotals(rowId: string = "", fee: number = 0, isPercentage: boolean = false) {
		this.allocationFeeTotal = 0;
		this.allocationFeePercentTotal = 0;
		if (!isEmpty(rowId)) {
			if (isPercentage) {
				this.allocationFeePercentTotal = isNaN(+fee) ? 0 : +fee;
			} else {
				this.allocationFeeTotal = isNaN(+fee) ? 0 : +fee.toFixed(2);
			}
		}

		const catalogs = this.catalogs ?? [];
		catalogs.forEach(catalog => {
			if ((catalog.rowId !== rowId && !isPercentage) || isPercentage) {
				const numberToAdd = NumberUtil.parseLocaleNumber(this.allocationFeeChangeLocale[catalog.rowId].toString());
				const total = +this.allocationFeeTotal.toFixed(2) + +numberToAdd;
				this.allocationFeeTotal = !isNaN(total) ? total : 0;
			}
			if ((catalog.rowId !== rowId && isPercentage) || !isPercentage) {
				const rawPercent = +(this.allocationFeeChangePercent[catalog.rowId]);
				const changePercent = +(!isNaN(rawPercent) && +rawPercent > 0 ? +rawPercent : 0).toFixed(2);
				const percentageTotal = +this.allocationFeePercentTotal + changePercent;
				if (this.allocationFeeTotal === this.totalFees) {
					this.allocationFeePercentTotal = 100;
				} else {
					this.allocationFeePercentTotal = !isNaN(percentageTotal) ? percentageTotal : 0;
				}
			}
		});

		this.calculateAllocationSpread$.next(rowId);
	}



	calculateAllocationSpread(amounts: IEntitySearchDoc[]) {
		// clear out the current spreads
		this.catalogSpreads = {};

		if (!this.useCatalogsInsteadOfAmounts) {
			// roll up or no amounts
			if (this.viewSelection === ViewSelection.Default && this.rollUp || amounts.length < 1) {
				this.rollupAmounts();
				return;
			}

			// evenly spread
			if (this.viewSelection === ViewSelection.Default) {
				this.evenlySpreadAmounts(amounts);
				return;
			}

			// FIFO
			if (this.viewSelection === ViewSelection.FirstInFirstOut) {
				this.fifoSpreadAmounts(amounts);
				return;
			}
		}
	}

	rollupAmounts() {
		const catalogs = this.catalogs ?? [];
		catalogs.forEach(catalog => {
			// add a single rolled up amount for the current catalog row
			const amount = NumberUtil.parseLocaleNumber(this.allocationFeeChangeLocale[catalog.rowId].toString()) as number
			const rolledUpAmout: IAllocatedAmount = {
				title: this.selected ? "Allocation Amount" : "Child Allocation",
				amount,
				percentage: isNaN(amount) ? 0 : +(100 * amount / amount).toFixed(2),
				dueDate: null,
				sourceCharTypeId: CharTypeId.Amount,
				sourceRecordId: -1
			};
			this.catalogSpreads[catalog.rowId] = [rolledUpAmout];
		});
	}

	evenlySpreadAmounts(amounts: IEntitySearchDoc[], spreadEvenlyByCatalog: boolean = false, allocationRow: IAllocationRow = null) {
		const catalogs = this.catalogs ?? [];
		catalogs.forEach(catalog => {
			const total = NumberUtil.parseLocaleNumber(this.allocationFeeChangeLocale[catalog.rowId].toString()) as number;
			const allocationTotal = this.allocationFeeTotal;
			const percent = total / allocationTotal;

			const amountRemainders = amounts.reduce((acc, next) => {
				const amountCd = (next.characteristics.amount_double as number) ?? 0;
				acc[next.entityID] = Math.max(amountCd, this.allocationFeeTotal) / this.catalogs.length;
				return acc;
			}, {});

			// calculate the spread value for each nested amount
			let spreadAmounts = [];
			//if dimension with catalogs hierarchy allocation is selected
			if (spreadEvenlyByCatalog) {
				amounts.forEach(amount => {
					const amountCd = (amount.characteristics.amount_double as number) ?? 0;
					const amountMoneyCd = (amount.characteristics.amount_money_double as number) ?? 0;
					const catalogs = allocationRow.catalogEntitiesForSuballocation ?? [];
					catalogs.forEach(catalog => {
						const amountPerRowCatalogInstallmentRow = percent * Math.max(amountCd, amountMoneyCd) / catalogs.length
						let spreadAmt = Math.trunc(amountPerRowCatalogInstallmentRow * 100) / 100;
						let remainder = amountRemainders[amount.entityID];
						if (spreadAmt > remainder) {
							spreadAmt = remainder;
							remainder = 0;
						} else {
							remainder = NumberUtil.bankRound(remainder - spreadAmt);
						}

						amountRemainders[amount.entityID] = remainder;
						const allocatedAmount: IAllocatedAmount = {
							title: catalog.title,
							amount: NumberUtil.bankRound(spreadAmt),
							percentage: isNaN(spreadAmt) ? 0 : +(100 * spreadAmt / total).toFixed(2),
							dueDate: amount.characteristics.due_date_date,
							sourceCharTypeId: CharTypeId.Property,
							sourceRecordId: catalog.recordID
						};
						spreadAmounts.push(allocatedAmount);
					})
				})
			} else {
				spreadAmounts = amounts.map(amount => {
					const amountCd = (amount.characteristics.amount_double as number) ?? 0;
					const amountMoneyCd = (amount.characteristics.amount_money_double as number) ?? 0;
					let spreadAmt = percent * Math.max(amountCd, amountMoneyCd);
					let remainder = amountRemainders[amount.entityID];
					if (spreadAmt > remainder) {
						spreadAmt = remainder;
						remainder = 0;
					} else {
						remainder = NumberUtil.bankRound(remainder - spreadAmt);
					}

					amountRemainders[amount.entityID] = remainder;

					const allocatedAmount: IAllocatedAmount = {
						title: amount.title,
						amount: NumberUtil.bankRound(spreadAmt),
						percentage: isNaN(spreadAmt) ? 0 : +(100 * spreadAmt / total).toFixed(2),
						dueDate: amount.characteristics.due_date_date,
						sourceCharTypeId: CharTypeId.Amount,
						sourceRecordId: amount.recordID
					};

					return allocatedAmount;
				})
			}

			// figure out if there is any money unaccounted for and add it to the last amount
			const spreadTotal = spreadAmounts.reduce((total, next) => total + next.amount, 0);
			const catalogTotal = NumberUtil.parseLocaleNumber(this.allocationFeeChangeLocale[catalog.rowId].toString()) as number
			const remainder = NumberUtil.toFixed(catalogTotal - spreadTotal, 2);
			if (remainder > 0) {
				const lastAmount = last(spreadAmounts);
				lastAmount.amount += remainder;
				lastAmount.percentage = isNaN(lastAmount.amount) ? 0 : +(100 * lastAmount.amount / total).toFixed(2);
			}
			if (spreadEvenlyByCatalog) {
				if (catalog.rowId === allocationRow.rowId) {
					this.catalogSpreads[allocationRow.rowId] = spreadAmounts;
				}
			} else {
				this.catalogSpreads[catalog.rowId] = spreadAmounts;
			}
		});
	}

	fifoSpreadAmounts(amounts: IEntitySearchDoc[]) {
		const catalogs = this.catalogs ?? [];
		const sortedCatalogs = cloneDeep(catalogs);
		sortedCatalogs.sort((a, b) => DateUtil.parseToMoment(a.licenseStartDate, DateLocaleType.Storage).unix() - DateUtil.parseToMoment(b.licenseStartDate, DateLocaleType.Storage).unix())
		const allocatedAmounts = amounts.map(amount => {
			const amountCd = (amount.characteristics.amount_double as number) ?? 0;
			const amountMoneyCd = (amount.characteristics.amount_money_double as number) ?? 0;
			const spreadAmt = Math.max(amountCd, amountMoneyCd);
			const allocatedAmount: IAllocatedAmount = {
				title: amount.title,
				amount: spreadAmt,
				percentage: 0,
				dueDate: amount.characteristics.due_date_date,
				sourceCharTypeId: CharTypeId.Amount,
				sourceRecordId: amount.recordID
			};
			return allocatedAmount;
		});
		const init: Dictionary<IAllocatedAmount[]> = {};
		this.catalogSpreads = sortedCatalogs.reduce((spreadResults, catalog) => {
			let totalRemaining = NumberUtil.parseLocaleNumber(this.allocationFeeChangeLocale[catalog.rowId].toString()) as number;
			const spreads: IAllocatedAmount[] = [];
			for (const a of allocatedAmounts) {
				// the amount is already spread, skip it
				if (a.amount <= 0) {
					continue;
				}

				const amt = cloneDeep(a);
				amt.percentage = isNaN(amt.amount) ? 0 : +(100 * amt.amount / totalRemaining).toFixed(2);
				const diff = Number((totalRemaining - amt.amount).toFixed(2));

				// see if this amount will be split between this catalog and the next
				if (diff <= 0) {
					totalRemaining = 0;
					a.amount = Math.abs(diff);
					amt.percentage = isNaN(amt.amount) ? 0 : +(100 * amt.amount / totalRemaining).toFixed(2);
					amt.amount += diff;
					spreads.push(amt);

					// stop looping, the catalog total is zero
					break;
				}

				// subtract the whole amount and continue looping
				a.amount = 0;
				totalRemaining = diff;
				spreads.push(amt);
			}

			spreadResults[catalog.rowId] = spreads;
			return spreadResults;
		}, init);
	}

	onSelectionChange() {
		this.isLoading = true;
		const sub = this.dataSource.fetchRows().pipe(
			switchMap(() => this.openOrCloseTables())
		).subscribe();
		this.subscriptions.push(sub);
	}

	private openOrCloseTables() {
		return this.dataSource.indexedRowData$
			.pipe(
				switchMap(rowData => {
					if (!rowData || rowData.length === 0) {
						return of();
					}
					const observables = rowData.map(rd => {
						const gridNestedStrategy = this.dataSource.nestedStrategy;
						return gridNestedStrategy.toggleOpen(rd.rowPath, rd.data, this.expandAllocationAmounts);
					});
					return forkJoin(observables);
				})
			);
	}

	checkForError(rowId: string) {
		return this.hasError[rowId] || this.amountErrors.get(rowId);

	}

	onItemChange(toggled: boolean = false) {
		if (this.viewSelection === ViewSelection.FirstInFirstOut && this.rollUp) {
			this.rollUp = false;
		}
		if (this.allocationType !== AllocationType.ByCatalogRightsDuration) {
			const amountToPopulate = this.totalFees / this.totalNumFound;
			const percentage = 100 / this.totalNumFound;
			this.catalogs.forEach(catalog => {
				if (this.singleRowFeePercentageEdit[catalog.rowId] && toggled) {
					this.singleRowFeePercentageEdit[catalog.rowId] = null;
				}
				if (this.singleRowFeeEdit[catalog.rowId] && toggled) {
					this.singleRowFeeEdit[catalog.rowId] = null;
				}
				if (!this.bulkAllocatedFeeAmount) {
					this.allocationFeeChangeLocale[catalog.rowId] = this.toServerAmount(amountToPopulate.toFixed(2).toString());
				} else {
					this.allocationFeeChangeLocale[catalog.rowId] = this.toServerAmount(this.bulkAllocatedFeeAmount.toString());
				}
				if (!this.bulkAllocatedFeePercentage) {
					this.allocationFeeChangePercent[catalog.rowId] = +percentage.toFixed(2);
				} else {
					this.allocationFeeChangePercent[catalog.rowId] = this.bulkAllocatedFeePercentage;
				}
			});
		}
		this.calculateAllocationSpread$.next();
	}

	calculateAllocations() {
		this.isSaving = true;
		const rowsToSubmit: AllocationRowToCreate[] = this.originalRows.map(row => {
			const fee = this.allocationFeeChangeLocale[row.rowId];
			const feeNumber = Number(fee.replace(/[^0-9.-]+/g, ""));
			const allocationRow: AllocationRowToCreate = {
				catalogRecordId: row.catalogId,
				paymentScheduleRecordId: row.paymentScheduleId,
				licenseStartDate: row.licenseStartDate,
				licenseEndDate: row.licenseEndDate,
				allocatedAmount: feeNumber,

				licensePeriod: row.licensePeriod,
				calculationMethod: this.allocationType !== AllocationType.ByCatalogRightsDuration ? "Duration of Rights Grant behavior" : "Evenly by Catalog Item behavior",
				associatedRights: row.associatedRights,
				feeTypeDescription: row.feeTypeDescription,
				rightRecordId: row.rightRecId,
				allocationRowAmounts: this.catalogSpreads[row.rowId],
				dimensionDictionary: row.dimensionDictionary
			};
			return allocationRow;
		});

		const spreadStrategy = this.selectedSpreadStrategy();
		this.dealService.calculateCustomAllocation(rowsToSubmit, this.dealId, spreadStrategy).subscribe((response) => {
			this.onCalculate.emit(response.jobId);
			this.close();
		});
	}

	private selectedSpreadStrategy(): AllocationSpreadStrategy {
		if (this.rollUp) {
			return AllocationSpreadStrategy.rollUp;
		}
		switch (this.viewSelection) {
			case ViewSelection.Default:
				return AllocationSpreadStrategy.default;
			case ViewSelection.FirstInFirstOut:
				return AllocationSpreadStrategy.fifo;
		}
	}

	public updateAmounts(amounts: IAllocatedAmount[], rowId: string) {
		//Check if child amounts summed = parent
		const sumOfAmounts = amounts.reduce((a, b) => +a + +b.amount, 0);
		const rowAmount = this.allocationFeeChangeLocale[rowId];
		if (sumOfAmounts !== Number(rowAmount.replace(/[^0-9.-]+/g, ""))) {
			if (!this.amountErrors[rowId] || this.amountErrors[rowId] === false) {
				this.amountErrors.set(rowId, true);
			}
		} else {
			this.amountErrors.set(rowId, false);
		}
		this.checkForAmountError();
		this.catalogSpreads[rowId] = amounts;
	}

	checkForAmountError() {
		let hasAmountError = false;
		if (this.amountErrors) {
			const map = this.amountErrors;

			map.forEach((val, key) => {
				if (val === true) {
					hasAmountError = true;
				}
			});
		}
		this.hasAnyAmountError = hasAmountError;
	}

	public close() {
		this.activeModal.close(false);
	}

	public dismiss() {
		this.activeModal.dismiss();
	}
}

