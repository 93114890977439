<div class="modal-body" *ngIf="policiesLoaded; else isLoading">
	<div class="d-flex flex-column policies-grid">
		<div class="d-flex policies-grid__row policies-grid__row--heading">
			<div class="policies-grid__col policies-grid__col--narrow">READ</div>
			<div class="policies-grid__col policies-grid__col--narrow">WRITE</div>
			<div class="policies-grid__col flex-fill"></div>
		</div>
		<div class="d-flex policies-grid__row policies-grid__row--sub-heading">
			<ng-container *ngFor="let securityRight of securityRights">
				<div class="policies-grid__col policies-grid__col--narrow d-flex justify-content-stretch p-0">
					<div class="success permissions-sm text-center" title="Allow All"
						(click)="setAll($event, securityRight.rightFlag, statusKey.allow)" data-cy="allowAll">
						<i class="far fa-check-circle"></i>
					</div>
					<div class="danger permissions-sm border-start border-end text-center" title="Deny All"
						(click)="setAll($event, securityRight.rightFlag, statusKey.deny)" data-cy="denyAll">
						<i class="far fa-times-circle"></i>
					</div>
					<div class="effective-neutral permissions-sm text-center" title="Set All Neutral"
						(click)="setAll($event, securityRight.rightFlag, statusKey.neutral)" data-cy="allNeutral">
						<i class="far fa-circle"></i>
					</div>
				</div>
			</ng-container>
			<div class="policies-grid__col flex-fill"></div>
		</div>
		<div class="d-flex policies-grid__row policies-grid__row--sub-heading" *ngFor="let policy of policies">
			<ng-container *ngFor="let securityRight of securityRights">
				<div class="policies-grid__col policies-grid__col--narrow" [title]="securityRight.name"
					[ngClass]="effectiveClass[policy.securityPolicyID][securityRight.rightFlag]">
					<rl-security-policy-cell [rightFlag]="securityRight.rightFlag" [description]="securityRight.name"
						[rightStatuses]="rightStatus[policy.securityPolicyID]" [rightFlag]="securityRight.rightFlag"
						(onChange)="toggle(policy, securityRight.rightFlag)">
					</rl-security-policy-cell>
				</div>
			</ng-container>
			<div class="policies-grid__col flex-fill">{{ policy.policyTitle }}</div>
		</div>

		<button role="button" class="btn btn-link btn-sm d-inline-block cursor-pointer p-1"
			(click)="resetPolicyChanges()" data-cy="resetPolicyChanges">Reset Policy Changes</button>
	</div>
</div>

<ng-template #isLoading>
	<div class="p-4 text-center">
		<rl-loader></rl-loader>
	</div>
</ng-template>